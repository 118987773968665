import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import {Input, Select, Modal, Spin, notification, Row, Col, Switch} from 'antd'
import { MessageOutlined, WarningOutlined } from '@ant-design/icons';
import {Icon, NavBar, Card, Steps, Button, Toast, Space, Dialog, Search, Dropdown, Radio, ActionSheet, Loading, Divider} from 'antd-mobile'
import { SearchOutline, CheckCircleOutline, LeftOutline, DownOutline, GiftOutline, LocationOutline } from 'antd-mobile-icons'
import axios from 'axios'
import MapComponent from '../MapComponent';
import queryString from "query-string"
import { useLocation, useParams } from 'react-router';
import Created from './Created';
import ReadyForPickup from './ReadyForPickup';
import AtSorting from './AtSorting';
import OnVehicleForDelivery from './OnVehicleForDelivery';
import { getTrackingInfo, confirmPostalCode, getWindows } from '../../util/APIUtils';
import DetailedView from './DetailedView';
import DeliveryFailed from './DeliveryFailed';
import DeliveryCompleted from './DeliveryCompleted';
import Active from './Active';
import { useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl, intlShape, useIntl  } from "react-intl";
import { use100vh } from 'react-div-100vh'
import PickedUp from './PickedUp';
import service from "../../static/images/service.svg";
import pack from "../../static/images/package.svg";
import signature from "../../static/images/signature.svg";
import boxknightLogoWhite from "../../static/images/boxknight_logo_white.svg";
import boltLogo from "../../static/images/GoBolt-Primary-for-Dark-Bg.png";
import {CopyOutlined} from '@ant-design/icons'
import {Helmet} from "react-helmet";
// import Search from 'antd/lib/transfer/search';
const { Step } = Steps;
const MIN_LINK_LENGTH = 30
// const operation = Modal.operation;
// const prompt = Modal.prompt;
const { Option } = Select;

const useInterval = (callback, delay) => {
  const savedCallback = useRef(callback)

  // Remember the latest callback if it changes.
  useLayoutEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    if (!delay) {
      return
    }

    const id = setInterval(() => savedCallback.current(), delay)

    return () => clearInterval(id)
  }, [delay])
}

const stylesheets = {
  light: "https://cdnjs.cloudflare.com/ajax/libs/antd/4.16.13/antd.min.css",
  dark: "https://cdnjs.cloudflare.com/ajax/libs/antd/4.16.13/antd.dark.min.css"
}

const chooseGoBoltLogo = process.env.REACT_APP_BASE_URL && process.env.REACT_APP_BASE_URL.includes('stage')
const isStage = process.env.REACT_APP_BASE_URL && process.env.REACT_APP_BASE_URL === 'https://api.tracking-stage.boxknight.com'

const OrderInfo = ({onChangeLanguage, modifyAddressFlag, modifyScheduleFlag}) => {
  const intl = useIntl()
  const history = useHistory()
  const location = useLocation()
  const [values, setValues] = useState(queryString.parse(location.search))
  const params = useParams()
  const [loading, setLoading] = useState(false)
  const [orderInfo, setOrderInfo] = useState(null)
  const handleOrderInfoUpdate = (o) => {
    setOrderInfo(o)
  }
  const handleRecipientAddressUpdate = (r) => {
    setRecipientAddress(r)
  }
  const handlePostalCodeUpdate = (p) => {
    setPostalCode(p)
  }
  const [visible, setVisible] = useState(false)
    const windowHeight = use100vh();
  
  const [postalCode, setPostalCode] = useState(null)
  const [postalCodeValid, setPostalCodeValid] = useState(false)
  const [loadingConfirmPostalCode, setLoadingConfirmPostalCode] = useState(false)
  const [showConfirmPostalCodePopover, setShowConfirmPostalCodePopover] = useState(false)
  const [recipientAddress, setRecipientAddress] = useState(null)
  const [themeMode, setThemeMode] = useState(null)

  const [myWindows, setMyWindows] = useState([])
  const [myCompleteAfterDate, setMyCompleteAfterDate] = useState(null)
  const [myCompleteBeforeDate, setMyCompleteBeforeDate] = useState(null)
  const [myCompleteAfterInt, setMyCompleteAfterInt] = useState(null)
  const [myCompleteBeforeInt, setMyCompleteBeforeInt] = useState(null)

  const [showConfirmScheduleModal, setShowConfirmScheduleModal] = useState(false)
  const [loadingConfirmSchedule, setLoadingConfirmSchedule] = useState(false)

  const [windowActionsVisible, setWindowActionsVisible] = useState(false)

  // const [recipientAddress, setRecipientAddress] = useState(null)
  const [recipient, setRecipient] = useState(null)

  const [warningShown, setWarningShown] = useState(false)

  const createStylesheetLink = () => {
  const link = document.createElement("link")
  link.rel = "stylesheet"
  link.id = "antd-stylesheet"
  document.head.appendChild(link)
  return link
}

const getStylesheetLink = () =>
  document.head.querySelector("#antd-stylesheet") || createStylesheetLink()

const systemTheme = () =>
  window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches
    ? "dark"
    : "light"

const getTheme = () => (localStorage.getItem("theme")) || systemTheme()

const setTheme = (t) => {
  setThemeMode(t === 'dark')
  localStorage.setItem("theme", t)
  getStylesheetLink().href = stylesheets[t]

  // if (t) {
  //   document.getElementById('dialogFooter').style.backgroundColor = '#000d1a'
  // }
  // else {
  //   document.getElementById('dialogFooter').style.backgroundColor = '#FFFFFF'
  // }
}

const toggleTheme = () => setTheme(getTheme() === "dark" ? "light" : "dark")

  const handleGetWindows = async (data, completeAfter = 1592262000000, completeBefore = 1592280000000, setNewWindow = false) => {
    if (data?.tracking?.rateChoice?.service!= null && data.tracking.rateChoice.service === "SCHEDULED" && data.tracking.completeBefore == null){
      // get windows
      let options = {
        "orderId": data.tracking.id,
        "completeAfter": completeAfter,
        "completeBefore": completeBefore,
        "getWindows": true
      }

      getWindows(options)
      .then(result => {
        setMyWindows(result.data)
      })
      .catch(error => {
        console.log(error)
      })
    }
  }

  const loadEverything = () => {
    loadTrackingInfo(true)
    
    if (values.postalCode!=null) {
      confirmPostalCode(values.trackingNo, values.postalCode)
      .then(res => {
          // Toast.show(intl.formatMessage({id: "app.postalCodeConfirmed"}), 2)
          setPostalCodeValid(true)
          setLoadingConfirmPostalCode(false)
          setShowConfirmPostalCodePopover(false)
          setRecipientAddress(res.data.recipientAddress)
          setRecipient(res.data.recipient)
          history.push(`/tracking?trackingNo=${values.trackingNo}&postalCode=${values.postalCode}`)
          setPostalCode(values.postalCode)
      })
      .catch(error => {
        // console.log(JSON.stringify(error))
          console.log(error)
          // Toast.show(intl.formatMessage({id: "app.postalCodeNotAssiocated"}), 2)
          setPostalCodeValid(false)
          setLoadingConfirmPostalCode(false)
      })
    }
  }

  const loadTrackingInfo = (myLoading = false) => {
    console.log('CALLING')
    if (myLoading) setLoading(true)
    getTrackingInfo(values.trackingNo)
    
    .then(res => {
      if (res?.data?.tracking?.dispatcher != null && res.data.tracking.dispatcher === 'GOBOLT') {
        if (isStage) window.location.replace(`https://galileo-preprod.herokuapp.com/tracking/${values.trackingNo}`)
        else window.location.replace(`https://galileo.gobolt.com/tracking/${values.trackingNo}`)
      }
      else {
        //alert(JSON.stringify(res.data))
        setOrderInfo(res.data)
        handleGetWindows(res.data)
        if (myLoading) setLoading(false)

        if (myLoading) {
          if (res?.data?.tracking?.completionDetails?.completionNotes) {
            notification.close('notes')
            notification.open({
              message: intl.formatMessage({id: "app.yourDriver"}),// }, {res.data.tracking.worker.name}, {intl.formatMessage({id: "app.hasLeftANote"})}</>:<>{intl.formatMessage({id: "app.yourDriver"})} {intl.formatMessage({id: "app.hasLeftANote"})}</>,
              description: <span style={{fontStyle: 'italic'}}>"{res.data.tracking.completionDetails.completionNotes}"</span>,
              duration: 0,
              key: 'notes',
              icon: <MessageOutlined style={{color: '#1890ff'}}/>
            });
          }
        }
        if (res?.data?.tracking?.warningMessage != null && !warningShown) {
          setWarningShown(true)
          notification.close('warningMessage')
          notification.open({
            message: 'Warning!',
            description: res.data.tracking.warningMessage,
            type: 'warning',
            duration: 0,
            key: 'warningMessage'
          });
        }
      }
      
    })
    .catch(error => {
      console.log(error)
      Toast.show({content: intl.formatMessage({id: "app.noDeliveryFound"}), position: 'top'})
      if (myLoading) setLoading(false)
      history.push('/')
    })
  }

  useEffect(() => {
    // notification.close('outage')
    // notification.open({
    //     message: intl.formatMessage({id: "app.outageNotif"}),
    //     duration: 0,
    //     key: 'outage',
    //     icon: <WarningOutlined style={{color: 'orange'}}/>
    // });
    if (params.trackingNo != null && params.trackingNo != '') {
      let currentValues = values;
      currentValues.trackingNo = params.trackingNo;
      setValues(currentValues);
    }
    setTheme(getTheme())
    loadEverything()
  }, [])
  
  useInterval(() => loadTrackingInfo(false), orderInfo?.tracking?.status && orderInfo.tracking.status === 'ACTIVE'? 10000:null)//:60000)//orderInfo?.tracking?.status && orderInfo.tracking.status === 'ACTIVE'? 5000: 60000)

  const handleConfirmPostalCode = async () => {
      setLoadingConfirmPostalCode(true)
      if (postalCode == null || postalCode.length === 0) {
        Toast.show(intl.formatMessage({id: "app.pleaseEnterAPostalCode"}), 2)
        setLoadingConfirmPostalCode(false)
        return
      }

      confirmPostalCode(values.trackingNo, postalCode)
        .then(res => {
            Toast.show(intl.formatMessage({id: "app.postalCodeConfirmed"}), 2)
            setPostalCodeValid(true)
            setLoadingConfirmPostalCode(false)
            setShowConfirmPostalCodePopover(false)
            setRecipientAddress(res.data.recipientAddress)
            setRecipient(res.data.recipient)
            history.push(`/tracking?trackingNo=${values.trackingNo}&postalCode=${postalCode}`)
        })
        .catch(error => {
          // console.log(JSON.stringify(error))
            console.log(error)
            Toast.show(intl.formatMessage({id: "app.postalCodeNotAssiocated"}), 2)
            setPostalCodeValid(false)
            setLoadingConfirmPostalCode(false)
        })
  }

  const confirmPostalCodeContent = () => {
    return (
      !postalCodeValid && 
        <>
          <Dialog
            visible={showConfirmPostalCodePopover}
            bodyStyle={{backgroundColor: themeMode ? "#000d1a": ""}}
            title={<span style={{color: themeMode?"#FFFFFF":''}}>{intl.formatMessage({id: "app.verifyYourIdentity"})}</span>}
            content={
              <>
                <span style={{color: themeMode?"#FFFFFF":''}}><FormattedMessage id="app.pleaseEnterToVerify"/></span>
                <Input
                  style={{marginTop: 10}} 
                  onChange={e => setPostalCode(e.target.value)} 
                  onKeyDown={e => {e.key ==='Enter' && handleConfirmPostalCode()}}
                  placeholder={intl.formatMessage({id: "app.inputPostalCode"})}
                  name="trackingNumber"
                  value={postalCode}
                  onSearch={handleConfirmPostalCode}
                />
                {loadingConfirmPostalCode && <p><Loading /></p>}
              </>
            }
            onClose={() => {
              setShowConfirmPostalCodePopover(false)
            }}
            actions={
              [
                [
                  {
                    key: 'cancel',
                    text: <span className="cancelButton" style={{color: themeMode?"#FFFFFF":''}}>{intl.formatMessage({id: "app.cancel"})}</span>,
                    onClick: () => setShowConfirmPostalCodePopover(false),
                    style: {backgroundColor: themeMode ? "#000d1a": ""}
                  },
                  {
                    key: 'confirm',
                    text: intl.formatMessage({id: "app.next"}),
                    onClick: handleConfirmPostalCode,
                    style: {backgroundColor: themeMode ? "#000d1a": ""}
                  }
                ]
              ]
            }
          />
          <Button
            block
            onClick={() => setShowConfirmPostalCodePopover(true)}
          >
            <LocationOutline/> <FormattedMessage id={orderInfo.tracking.status !== 'ACTIVE'?"app.loadDetailedInformation":"app.viewLiveTracking"}/>
          </Button>
        </>
    )
  }
  const renderSwitch = () => {
    switch(orderInfo.tracking.status) {
      case 'CREATED':
        return <Created 
                  orderInfo={orderInfo} 
                  trackingNo={values.trackingNo} 
                  scheduleSelector={scheduleSelector} 
                  info={info} 
                  customerInfo={customerInfo} 
                  postalCodeValid={postalCodeValid}
                  themeMode={themeMode}
                />
      case 'PICKED_UP':
        return <PickedUp 
                  orderInfo={orderInfo} 
                  trackingNo={values.trackingNo} 
                  scheduleSelector={scheduleSelector} 
                  info={info} 
                  customerInfo={customerInfo} 
                  postalCodeValid={postalCodeValid}
                  themeMode={themeMode}
                />
      case 'READY_TO_PICKUP':
        return <ReadyForPickup 
                  orderInfo={orderInfo} 
                  trackingNo={values.trackingNo} 
                  scheduleSelector={scheduleSelector} 
                  info={info} 
                  customerInfo={customerInfo} 
                  postalCodeValid={postalCodeValid}
                  themeMode={themeMode}
                />
      case 'AT_SORTING':
        return <AtSorting 
                  orderInfo={orderInfo} 
                  trackingNo={values.trackingNo} 
                  scheduleSelector={scheduleSelector} 
                  info={info} 
                  customerInfo={customerInfo} 
                  postalCodeValid={postalCodeValid}
                  themeMode={themeMode}
                />
      case 'ON_VEHICLE_FOR_DELIVERY':
        return <OnVehicleForDelivery 
                  orderInfo={orderInfo} 
                  trackingNo={values.trackingNo} 
                  scheduleSelector={scheduleSelector} 
                  info={info} 
                  customerInfo={customerInfo} 
                  postalCodeValid={postalCodeValid}
                  themeMode={themeMode}
                />
      case 'ACTIVE':
        return <Active 
                  orderInfo={orderInfo} 
                  trackingNo={values.trackingNo} 
                  confirmPostalCodeContent={confirmPostalCodeContent} 
                  scheduleSelector={scheduleSelector}
                  info={info} 
                  customerInfo={customerInfo} 
                  postalCodeValid={postalCodeValid}
                  themeMode={themeMode}
                />
      case 'DELIVERY_FAILED':
        return <DeliveryFailed 
                  orderInfo={orderInfo} 
                  trackingNo={values.trackingNo} 
                  postalCodeValid={postalCodeValid} 
                  scheduleSelector={scheduleSelector}
                  info={info} 
                  customerInfo={customerInfo} 
                  themeMode={themeMode}
                />
      case 'DELIVERY_COMPLETED':
        return <DeliveryCompleted 
                  orderInfo={orderInfo} 
                  trackingNo={values.trackingNo} 
                  postalCodeValid={postalCodeValid} 
                  scheduleSelector={scheduleSelector}
                  info={info} 
                  customerInfo={customerInfo} 
                  themeMode={themeMode}
                />
      default:
        return 'Status Unavailable';
    }
  }

  const chooseView = () => {
    if (postalCodeValid && recipientAddress) {
      return <DetailedView 
              renderSwitch={renderSwitch} 
              recipientAddress={recipientAddress} 
              orderInfo={orderInfo} 
              windowHeight={windowHeight} 
              handleOrderInfoUpdate={handleOrderInfoUpdate} 
              postalCode={postalCode} 
              handleRecipientAddressUpdate={handleRecipientAddressUpdate} 
              handlePostalCodeUpdate={handlePostalCodeUpdate} 
              footer={footer}
              modifyAddressFlag={modifyAddressFlag} 
              modifyScheduleFlag={modifyScheduleFlag}
              themeMode={themeMode}
            />
    }
    else {
      return renderSwitch()
    }
  }

  const left = (
    // same width as right
    <div style={{width: '38.6875px'}}>
      <Space style={{float: 'left'}}>
        <LeftOutline onClick={() => history.push('/')}/>
      </Space>
    </div>
  )

  const actions = [
    { text: 'English', key: 'en' },
    { text: 'Français', key: 'fr'}
  ]

  const copyToClipBoard = () => {
    if (navigator && navigator.clipboard){
      navigator.clipboard.writeText(orderInfo.tracking.id); 
      Toast.show(intl.formatMessage({id: "app.copied"}), 2)
    }
    else {
      Toast.show(intl.formatMessage({id: "app.couldNotCopy"}), 2)
    }
    
  }

  const info = () => {
    return (
      <>
      {orderInfo && <div 
        // ref={infoCardRef} 
        style={{overflowY: 'auto', fontWeight: 12}}
      >
        <div>
          <div className="title"><FormattedMessage id="app.trackingNumber"/></div>
          <div style={{textAlign: 'left', fontWeight: 'bold'}}>
            {orderInfo.tracking.id}
          </div>
        </div>
        <Row>&nbsp;</Row>
        <Row>
          <Col span={12}>
            <div className="title">Service</div>
            <div style={{textAlign: 'left', fontWeight: 'bold'}}>
              {orderInfo.tracking.rateChoice.service}
            </div>
          </Col>
          <Col span={12}>
            <div className="title"><FormattedMessage id="app.numberOfPackages"/></div>
            <div style={{textAlign: 'left', fontWeight: 'bold'}}>
              {orderInfo.tracking.packageCount}
            </div>
          </Col>
        </Row>
        <Row>&nbsp;</Row>
        <div>
          <div className="title">{orderInfo.tracking.signatureRequired === true? <FormattedMessage id="app.signatureRequired"/>:<FormattedMessage id="app.safeDropAllowed"/>}</div>
          <div style={{textAlign: 'left', fontWeight: 'bold'}}>
            {orderInfo.tracking.signatureRequired === true? <FormattedMessage id="app.signatureIsRequired"/>:<FormattedMessage id="app.signatureIsNotRequired"/>}
          </div>
        </div>
        {/* {!postalCodeValid || (orderInfo.tracking.status != 'ACTIVE' && orderInfo.tracking.status != 'DELIVERY_COMPLETED'&& orderInfo.tracking.status != 'DELIVERY_FAILED')?
        <div>
          <Divider style={{marginTop: 12, marginBottom: 12}}/>
        </div>: ''}
        {!postalCodeValid?
          <div>
            {confirmPostalCodeContent()}
          </div>
          :
            detailedViewContent()
          }           */}
      </div>}
      </>
    )
  }
  
  const customerInfo = () => {
    return (
      <>
      {orderInfo && recipientAddress && recipient? <div 
        // ref={infoCardRef} 
        style={{overflowY: 'auto', fontWeight: 12}}
      >
        <div>
          <div className="title"><FormattedMessage id="app.name"/></div>
          <div style={{textAlign: 'left', fontWeight: 'bold'}}>
            {recipient.name}
          </div>
        </div>
        <Row>&nbsp;</Row>
        {recipient.phone && !recipient.phone.startsWith('+++++++') && <div>
          <div className="title"><FormattedMessage id="app.phone"/></div>
          <div style={{textAlign: 'left', fontWeight: 'bold'}}>
            {recipient.phone}
          </div>
          <Row>&nbsp;</Row>
        </div>}
        <div>
          <div className="title"><FormattedMessage id="app.address"/></div>
          <div style={{textAlign: 'left', fontWeight: 'bold'}}>
            {recipientAddress.unit && <><FormattedMessage id="app.unit"/>: {recipientAddress.unit}, </>}
            {recipientAddress.number && <>{recipientAddress.number} </>}
            {recipientAddress.street && <>{recipientAddress.street}</>}
            {recipientAddress.postalCode && <>, {recipientAddress.postalCode}</>}
            {recipientAddress.province && <>, {recipientAddress.province}</>}
            {recipientAddress.country && <>, {recipientAddress.country}</>}
          </div>
        </div>
      </div>: ''}
      </>
    )
  }

  const footer = () => {
    return (
      <div className="footerContainer">
        {/* <span><GiftOutline style={{fontSize: '125%'}}/> {orderInfo.tracking.rateChoice.service} <img key={"1"} src={pack}/> {orderInfo.tracking.packageCount} <img key={"1"} src={signature}/> {orderInfo.tracking.signatureRequired? intl.formatMessage({id:"app.yes"}):intl.formatMessage({id:"app.no"})}</span> */}
        {/* <div className="footerInfoContainer">
          <div style={{float: 'left'}}>service: {orderInfo.tracking.rateChoice.service}</div>
          <div style={{float: 'middle',margin:'0 auto'}}>#packages: {orderInfo.tracking.packageCount}</div>
          {orderInfo.tracking.signatureRequired != null&&<div style={{float: 'right'}}>signature required: {orderInfo.tracking.signatureRequired? intl.formatMessage({id:"app.yes"}):intl.formatMessage({id:"app.no"})}</div>}
        </div> */}
        <div className="merchantFooterDivider">
          <Divider className="merchantFooterDivider"/>
        </div>
        
        {/* <div className="footerInfoContainer">
          <div style={{float: 'left'}}>service: {orderInfo.tracking.rateChoice.service}</div>
          <div style={{float: 'middle',margin:'0 auto'}}>#packages: {orderInfo.tracking.packageCount}</div>
          {orderInfo.tracking.signatureRequired != null&&<div style={{float: 'right'}}>signature required: {orderInfo.tracking.signatureRequired? intl.formatMessage({id:"app.yes"}):intl.formatMessage({id:"app.no"})}</div>}
        </div> */}

        <div className="statusUpdates"><FormattedMessage id="app.trackingNumber"/></div>
        <div className="trackingNumber">
          <div style={{float: 'left'}}>{orderInfo.tracking.id}</div>
          <div style={{float: 'right'}}><Button onClick={copyToClipBoard} fill='none' color={themeMode ? '#FFFFFF' : '#000000'} type="link" style={{border: 'none', boxShadow: 'none', padding: 0}}><CopyOutlined/></Button></div>
        </div>

        {/* <div className="statusUpdates">Delivery Service</div>
        <div className="trackingNumber">
          <div style={{float: 'left'}}>{orderInfo.tracking.rateChoice.service}</div>
        </div>
        <div className="statusUpdates"># of packages</div>
        <div className="trackingNumber">
          <div style={{float: 'left'}}>{orderInfo.tracking.packageCount} package(s)</div>
        </div>
        <div className="statusUpdates">Signature Required</div>
        <div className="trackingNumber">
          <div style={{float: 'left'}}>{orderInfo.tracking.signatureRequired? intl.formatMessage({id:"app.yes"}):intl.formatMessage({id:"app.no"})}</div>
        </div> */}
      </div>
    )
  }

  const scheduleSelector = () => {

    const onScheduleDateSelected = (orderId, value) => {
      //console.log(event, orderId)
      // get windows
      var completeAfterInt = parseInt(value.split("-")[0]);
      var completeBeforeInt = parseInt(value.split("-")[1]);
      var completeAfterDate = new Date(completeAfterInt); 
      var completeBeforeDate = new Date(completeBeforeInt); 

      setMyCompleteAfterInt(completeAfterInt)
      setMyCompleteBeforeInt(completeBeforeInt)
      setMyCompleteAfterDate(completeAfterDate)
      setMyCompleteBeforeDate(completeBeforeDate)
      setShowConfirmScheduleModal(true)
  
    }
    const generateScheduleSelect = (orderId, windows, selectSchedulePlaceholder) => {
      windows = windows.sort(function (a, b) {
        var aDate = a.date;
        var bDate = b.date;
        var aFrom = a.deliveryWindow.timeStart;
        var bFrom = b.deliveryWindow.timeStart;
        var aTo = a.deliveryWindow.timeEnd;
        var bTo = b.deliveryWindow.timeEnd;
    
        if(aDate == bDate){
          if (aFrom == bFrom){
            return (aTo < bTo) ? -1 : (aTo > bTo) ? 1 : 0;
          }
          return (aFrom < bFrom) ? -1 : (aFrom > bFrom) ? 1 : 0;
        }
        else{
          return (aDate < bDate) ? -1 : 1;
        }
      })
      if (windows.length > 0 && windows[0].deliveryWindow.timeStart <= "10:00"){
        windows = windows.slice(1)
      }
      //console.log("sorted windows", windows)
      let optionList =windows.map((window) => {
        let dateList = window.date.split("-")
        dateList[1] = parseInt(dateList[1]) - 1 //month are from 0-11
        let startHourList = window.deliveryWindow.timeStart.split(":")
        let endHourList = window.deliveryWindow.timeEnd.split(":")
  
        let startDateObj = new Date(...dateList.concat(startHourList))
        let endDateObj = new Date(...dateList.concat(endHourList))
        //return `${window.weekday} ${window.deliveryWindow.timeStart}-${window.deliveryWindow.timeEnd} (${window.date})`;
        // let final = <>{`${startDateObj.getTime()}-${endDateObj.getTime()}`}>{`${startDateObj.toDateString()} ${startDateObj.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})} - ${endDateObj.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})}`}</>
        return <Option value={`${startDateObj.getTime()}-${endDateObj.getTime()}`}>{`${startDateObj.toDateString()} ${startDateObj.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})} - ${endDateObj.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})}`}</Option>
        // return { text: `${startDateObj.toDateString()} ${startDateObj.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})} - ${endDateObj.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})}`, key: `${startDateObj.getTime()}-${endDateObj.getTime()}` }
      })
      console.log('my list')
      console.log(optionList)
      // alert('my name is')
      return (
        <div>
          {/* <Button color="primary" onClick={() => setWindowActionsVisible(true)}>{intl.formatMessage({id: 'app.selectSchedulePlaceholder'})}</Button> */}
          <ActionSheet
            visible={windowActionsVisible}
            actions={optionList}
            onClose={() => setWindowActionsVisible(false)}
            cancelText={intl.formatMessage({id: "app.cancel"})}
            onAction={action => {
              onScheduleDateSelected(orderId, action.key)
            }}
          />
          <Select placeholder={selectSchedulePlaceholder} onSelect={v => onScheduleDateSelected(orderId, v)} style={{ width: 300 }} >
            {optionList}
          </Select>
          {myCompleteAfterInt && myCompleteBeforeInt && myCompleteAfterDate && myCompleteBeforeDate &&
            <Modal
            title={intl.formatMessage({id: 'app.confirmSchedule'})}
            visible={showConfirmScheduleModal}
            okText={intl.formatMessage({id: "app.confirm"})}
            cancelText={intl.formatMessage({id: "app.cancel"})}
            onOk={() => {
              if (orderInfo?.tracking?.rateChoice?.service!= null && orderInfo.tracking.rateChoice.service === "SCHEDULED" && orderInfo.tracking.completeBefore == null){
                // get windows
                setLoadingConfirmSchedule(true)
                let options = {
                  "orderId": orderInfo.tracking.id,
                  "completeAfter": myCompleteAfterInt,
                  "completeBefore": myCompleteBeforeInt,
                  "getWindows": false
                }
          
                getWindows(options)
                .then(result => {
                  // setMyWindows(result.data)
                  if (orderInfo != null) {
                    // let newOrderInfo = {...orderInfo, tracking: {...orderInfo.tracking, completeAfter: myCompleteAfterInt, completeBefore: myCompleteBeforeInt}}
                    // setOrderInfo(newOrderInfo)
                    loadEverything()
                    setShowConfirmScheduleModal(false)
                    setLoadingConfirmSchedule(false)
                    setWindowActionsVisible(false)
                  }
                })
                .catch(error => {
                  Toast.show(intl.formatMessage({id: "app.couldNotSetSchedule"}), 2)
                  console.log(error)
                  setShowConfirmScheduleModal(false)
                  setLoadingConfirmSchedule(false)
                  setWindowActionsVisible(false)
                })
              }
            }}
            onCancel={() => setShowConfirmScheduleModal(false)}
          >
            <Spin spinning={loadingConfirmSchedule}>
            {`${intl.formatMessage({id: 'app.scheduleWillBe'})}: ${myCompleteAfterDate.toDateString()} ${myCompleteAfterDate.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})} - ${myCompleteBeforeDate.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})}`}
            </Spin>
          </Modal>
          }
        </div>
      )
    }

    if (orderInfo?.tracking?.rateChoice?.service != null && orderInfo.tracking.rateChoice.service === 'SCHEDULED' && orderInfo.tracking.completeBefore == null) {
      return generateScheduleSelect(orderInfo.tracking.id, myWindows, intl.formatMessage({id: 'app.selectSchedulePlaceholder'}))
    }
  }

  return (
    <div className="noselect">
    <div className="orderInfoContainer" style={{backgroundColor: themeMode ? "#000d1a": '#FFFFFF'}}>
      <div className="navbarContainer" style={{backgroundColor: "#001529"}}>
        <div className="navbarLeft">
          <img
                  key={"1"}
                  // src={boxknightLogoWhite}
                  src={chooseGoBoltLogo? boltLogo : boxknightLogoWhite}
                  alt="logo"
                  style={{width: chooseGoBoltLogo? "60px" : "100px", float: 'left'}}
                  color="#FFFFFF"
                  // className="onTheWay"
              />
        </div>
          <div className="navbarCenter">
              <img
                  key={"1"}
                  src={chooseGoBoltLogo? boltLogo : boxknightLogoWhite}
                  alt="logo"
                  style={{width: "100px", display: "none"}}
                  color="#FFFFFF"
                  // className="onTheWay"
              />
          </div>
          <div className="navbarRight">
            <Button style={{color: '#FFFFFF', padding:0, float: 'right', marginLeft: '1.8vw', fontWeight: 500}} fill='none' onClick={() => setVisible(true)}>
              {intl.locale.charAt(0).toUpperCase() + intl.locale.slice(1)} 
              <DownOutline/>
            </Button>
            <Switch
                    checkedChildren={<h1>🌒</h1>}
                    unCheckedChildren={<h1>🌤</h1>}
                    onChange={toggleTheme}
                    checked={themeMode}
                  />
          </div>
      </div>
      
      <span className="dropDownWrapper">
          <ActionSheet
              visible={visible}
              actions={actions}
              onClose={() => setVisible(false)}
              onAction={action => {onChangeLanguage(action.key); setVisible(false)}}
          />
      </span>
      {/* {loading || orderInfo == null || orderInfo.tracking == null? 
      Toast.show({icon: 'loading', content: intl.formatMessage({id: "app.loading"})})
      :  */}
      <Spin spinning={loading || orderInfo == null|| orderInfo.tracking == null}>
        {loading || orderInfo == null || orderInfo.tracking == null? 
        ''
        :
        <>
          {chooseView()}
          {orderInfo.tracking.status !== 'ACTIVE' &&
          <div className="buttonContentBodyContainer">
            {confirmPostalCodeContent()}
          </div>}
          {!postalCodeValid && footer()}
        </>}
      </Spin>
    </div>
    </div>
  );
}
export default OrderInfo
